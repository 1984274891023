<template>
  <div>
    <b-card title="Rounds Information">
      <b-container>
        <b-row>
          <b-col cols="5">
            <bingoroom-running-round />
          </b-col>

          <b-col cols="5">
            <bingoroom-forbet-round />
          </b-col>
          <b-col cols="2"> <bingoroom-jackpot-value /> </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card title="Chat Moderator">
      <b-container>
        <b-row>
          <b-col cols="5"> <chat-user-list /> </b-col>
          <b-col cols="7">
            <chat-message-container />
            <chat-message-send-input
          /></b-col>
        </b-row>
      </b-container>
    </b-card>
    <chat-moderator-sdk ref="sdk" />
  </div>
</template>

<script>
import { BCard, BContainer, BRow, BCol } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BContainer,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  async mounted() {
    await customElements.whenDefined("chat-moderator-sdk");
    const sdk = document.querySelector("chat-moderator-sdk");

    const url = config.VUE_CONFIG_SIGNALR;
    const data = {
      partnerId: "MODERATORS",
      userId: window.localStorage.getItem("userId"),
      name: window.localStorage.getItem("username"),
      currencyCode: "R$",
      languageCode: "BRL",
      userType: 2,
    };

    // https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-5.0
    if (navigator && navigator["locks"] && navigator["locks"].request) {
      const promise = new Promise((res) => {
        this.lockResolver = res;
      });

      navigator["locks"].request(
        "cpanel_moderator_lock_to_no_sleep",
        { mode: "shared" },
        () => {
          return promise;
        }
      );
    }

    if (document["pictureInPictureEnabled"]) {
    } else {
      if (this.isMobile) {
        this.fixBtnStyle = "";
      }
    }

    const response = await fetch(`${url}/player/login`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    const information = await response.json();
    console.log(information);
    // const parentId = 'TEST'

    await sdk.configure({
      AppId: data.partnerId,
      TokenId: information.tokenId,
      SessionId: information.sessionId,
      SignalRUrl: url,
    });

    const userId = window.localStorage.getItem("userId");
    if (userId === undefined) window.location.href = "/login";

    await customElements.whenDefined("bingoroom-running-round");
    const elem = document.querySelector("bingoroom-running-round");
    elem.addEventListener("newWinner", (event) => {
      event.detail.forEach((x) => {
        const txt = `${x.userName} GANO ${x.prizeCode.replace("", "")}`;
        this.$bvToast.toast(txt, {
          title: `Nuevo Ganador!! ${x.prizeCode.replace("", "")}`,
          variant: "success",
          toaster: "b-toaster-top-right",
          solid: true,
          autoHideDelay: 10000,
        });
      });
    });
    /*
    await customElements.whenDefined('chat-message-container')
    const chat = document.querySelector('chat-message-container')

    setTimeout(() => {
      chat.addEventListener('newMessage', event => {
        this.$bvToast.toast(event.detail.message, {
          title: `${event.detail.user.name} dice:`,
          variant: 'primary',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          autoHideDelay: 3000,
        })
      })
    }, 5000)
    */
  },
  methods: {},
};
</script>

<style></style>
